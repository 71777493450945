import * as $ from 'jquery';
function getUrlParameter(sParam) {
    const searchParamters = new URLSearchParams(window.location.search);
    const value = searchParamters.get(sParam);
    if (value === null)
        return null;
    if (value === "")
        return true;
    return value;
}
function show_login() {
    $(".only-register").hide();
    $(".only-login").show();
}
function show_register() {
    $(".only-login").hide();
    $(".only-register").show();
}
$(() => {
    if (getUrlParameter('login') == "1") {
        show_login();
    }
    $(".entry-switcher").on("click", ".switch-login", function (e) {
        e.preventDefault();
        show_login();
    });
    $(".entry-switcher").on("click", ".switch-register", function (e) {
        e.preventDefault();
        show_register();
    });
});
