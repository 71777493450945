const entrypoints = new Map();
export function registerEntryPoint(name, loader) {
    let _current_reset = async () => { };
    entrypoints.set(name, async (...args) => {
        const [_, { default: runner }] = await Promise.all([_current_reset(), loader()]);
        const unloader = await runner(...args) || (async () => { });
        _current_reset = async () => {
            if (!unloader)
                return;
            await unloader();
        };
    });
}
window["startEntrypoint"] = function startEntrypoint(name, ...args) {
    if (!entrypoints.has(name))
        return;
    const ep = entrypoints.get(name);
    ep(...args).catch(console.error);
};
